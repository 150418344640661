<template>
    <div class="flex p-8 flex-col gap-2 items-center relative bg-transparent">
        <AppUiIconAttention/>
        <div class="text-center mb-8 font-light leading-5 text-sm text-[#292b33]">
            Упс :( <br/>
            Извините, произошла ошибка.<br/>
            Повторите попытку или оставьте заявку.
        </div>
        <AppUiBtn text="Повторить" @click="restart"/>
    </div>
</template>

<script>
import {useNav} from "~/store/app/nav";

export default {
    setup() {
        const navStore = useNav();
        return {
            navStore,
        }
    },
    methods: {
        restart() {
            this.navStore.error = false;
            this.navStore.prev();
        }
    }
}
</script>