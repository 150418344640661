<template>
    <div
            v-if="show"
            class="fixed bg-white bg-opacity-95 left-0 right-0 bottom-0 transition-transform duration-500 lg:shadow-none shadow-xl z-10 py-4 md:py-5 text-center rounded-lg"
            style="border-top-left-radius: 50px; border-top-right-radius: 50px"
    >
        <div
                class="font-medium text-2xl sm:text-2xl lg:text-3xl leading-6 sm:leading-9 text-stone-700 my-4 px-2"
        >
            Ой! С 1 сентября у нас поменяются цены. <br/>
            Совсем чуть-чуть. <br/>
            <br/>
            Лайфхак: с депозита все услуги списываются со скидкой до 15%.<br/>
            И это навсегда ♥
        </div>
        <div class="text-center">
            <a
                    @click="hidePromo()"
                    class="w-full cursor-pointer mt-2 inline-flex md:text-base items-center text-xs uppercase justify-center border border-pink-600 rounded-full py-4 md:py-6 md:px-10 px-4 mb md:mb-0 font-medium md:tracking-wider text-pink-600 bg-white hover:text-black transition-colors mx-2 max-w-[70%] md:max-w-xl"
            >
                Ок
            </a>
        </div>
    </div>
</template>

<script>
import {useSlots} from "~/store/slots";

export default {
    setup() {
        const slotsStore = useSlots();
        return {
            slotsStore
        }
    },
    data() {
        return {
            show: false,
        };
    },
    mounted() {
        if (
            !window.localStorage.promo0109
        ) {
            this.show = true;
        }
    },
    methods: {
        hidePromo() {
            window.localStorage.promo0109 = true;
            this.show = false;
        },
    },
};
</script>
